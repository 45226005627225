<template>
	<div class="setting d-flex flex-column" style="margin-top: 0px">
		<!-- This is the header block -->
		<v-row no-gutters dense class="d-flex flex-grow-0">
			<v-col cols="12">
				<v-row no-gutters dense>
					<v-col cols="12" class="pa-0">
						<v-row no-gutters>
							<v-col cols="12" class="pa-0">
								<p class="my-heading">Settings</p>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12" class="pa-0">
								<ul class="setting-menu">
									<li
										class="tab-content"
										:class="{ 'tab--active': this.$store.state.setting.setting_tab.tab === 'company_profile' }"
										v-on:click="changeTab('company_profile')"
									>
										Company profile
									</li>
									<li
										class="tab-content"
										:class="{ 'tab--active': this.$store.state.setting.setting_tab.tab === 'account_transaction' }"
										v-on:click="changeTab('account_transaction')"
									>
										Account Detail & Transactions
									</li>
									<li
										class="tab-content"
										:class="{ 'tab--active': this.$store.state.setting.setting_tab.tab === 'product_category' }"
										v-on:click="changeTab('product_category')"
									>
										Product Categories
									</li>
									<li
										class="tab-content"
										:class="{ 'tab--active': this.$store.state.setting.setting_tab.tab === 'authorize_user' }"
										v-on:click="changeTab('authorize_user')"
									>
										Authorized Users
									</li>
									<li
										class="tab-content"
										:class="{ 'tab--active': this.$store.state.setting.setting_tab.tab === 'delivery_zone' }"
										v-on:click="changeTab('delivery_zone')"
									>
										Delivery Zones
									</li>
									<li
										class="tab-content"
										:class="{ 'tab--active': this.$store.state.setting.setting_tab.tab === 'pass_pref' }"
										v-on:click="changeTab('pass_pref')"
									>
										Password & Preferences
									</li>
								</ul>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-row no-gutters dense class="d-flex">
			<v-col cols="12" class="pa-0">
				<app-company-profile v-show="this.$store.state.setting.setting_tab.tab === 'company_profile'"></app-company-profile>
				<app-product-category v-show="this.$store.state.setting.setting_tab.tab === 'product_category'"></app-product-category>
				<app-authorize-user v-show="this.$store.state.setting.setting_tab.tab === 'authorize_user'"></app-authorize-user>
				<app-delivery-zone v-show="this.$store.state.setting.setting_tab.tab === 'delivery_zone'"></app-delivery-zone>
				<app-pass-pref v-show="this.$store.state.setting.setting_tab.tab === 'pass_pref'"></app-pass-pref>
				<app-account-transaction v-show="this.$store.state.setting.setting_tab.tab === 'account_transaction'"></app-account-transaction>
			</v-col>
		</v-row>
		<v-col class="footer d-flex align-end justify-end pa-0 ma-0" cols="12">
			<div>{{ this.version }}</div>
		</v-col>
	</div>
</template>
<script>
const CompanyProfile = () => import('./CompanyProfile') // "./CompanyProfile";
const ProductCategory = () => import('./ProductCategory')
const AuthorizeUser = () => import('./AuthorizeUser')
const DeliveryZone = () => import('./DeliveryZone')
const PassPref = () => import('./Pass_pref')
const AccountTransaction = () => import('./AccountTransaction')
import { Build_Version } from '../../config/variable'
import { getSetting } from '../../services/settingservice'
export default {
	components: {
		AppCompanyProfile: CompanyProfile,
		AppProductCategory: ProductCategory,
		AppAuthorizeUser: AuthorizeUser,
		AppDeliveryZone: DeliveryZone,
		AppPassPref: PassPref,
		AppAccountTransaction: AccountTransaction,
	},

	data() {
		return {
			version: Build_Version,
			tab: 'company_profile',
		}
	},

	async mounted() {
		this.$store.commit('settingTab', 'company_profile')
		await getSetting()
	},

	methods: {
		changeTab(tab_name) {
			this.$store.commit('settingTab', tab_name)
		},
	},
}
</script>

<style lang="scss" scoped>
    .setting {
    	min-height: 100vh;
    	margin-top: -80px;
    	padding: 112px 32px 32px 32px;

    	.setting-menu {
    		padding: 0px !important;
    		max-width: 100%;
    		background-color: #eceff6;
    		border-radius: 8px;
    		list-style-type: none;
    		display: flex;

    		.tab-content {
    			cursor: pointer;
    			font-family: Inter-Medium;
    			font-size: 14px !important;
    			color: #212121 !important;
    			text-transform: capitalize !important;
    			padding: 24px 32px;
    			width: auto;
    			margin: 0px auto;
    		}

    		.tab--active {
    			color: #2974ff !important;
    			border-bottom: 2px solid #2974ff;
    			border-spacing: 8px;
    			padding-bottom: 22px;
    		}
    	}

    	.footer {
    		z-index: 9;
    		padding-top: 8px !important;
    		margin-bottom: -16px !important;
    		font-family: Inter-Medium !important;
    		font-size: 12px !important;
    		color: rgba(79, 91, 109, 0.61);
    		text-align: right;
    	}
    }
</style>
